<template>
  <div>
    <!-- head title -->
    <div class="my-10">
      <span class="darkprimary--text font-33 font-700 d-block">{{$t('forms.verifyAccount')}}</span>
      <span class="pruple--text font-21 font-500 d-block"
        >{{$t('forms.checkEmail')}}</span
      >
    </div>

    <!-- Verification code -->
    <v-form v-model="valid" ref="clinicValidationStep" @submit.prevent="handleNext">

    <div class="mt-4">
      <span class="darktext--text font-18 font-600">{{$t('forms.verificationCode')}}</span>
      <base-input
        v-model="verifyCode"
        class="mt-1"
        type="number"
        :placeholder="$t('forms.enterVerificationCode')"
          :hideDetails="false"
      ></base-input>
    </div>

    <!-- btn -->
    <base-register-btn
      class="mt-2"
      :title="$t('forms.verify')"
      :loading="loading"
      type="submit"
    >
    </base-register-btn>
    </v-form>
  </div>
</template>

<script>
export default {
  props: {
    emailObj: {
      type: Object,
      default: () => {},
    },
  },
  data: () => ({
    loading: false,
    verifyCode: "",
  }),
  methods: {
    async handleNext() {
            let valid = this.$refs.clinicValidationStep.validate();
      if (!valid) {
        return;
      }      
      this.loading = true

      let formData = new FormData();

      formData.append("email", this.emailObj.email);
      formData.append("code", this.verifyCode);
      let res = await this.$axios.post(
        "code/check",
        formData
      );
      this.loading = false
     if (res.success || res.status == 200) {
        this.$emit("next");
      } else {
        this.$store.dispatch("showSnack", {
          text: res.message,
          color: "error",
        });
      }      
    },
  },
  created() {
    this.verifyCode = this.emailObj.verification_code;
  },
};
</script>

<style></style>

<template>
  <div class="reset-password" v-if="resetCode">
    <transition name="fade" mode="out-in">
      <!-- verify step -->
      <verify-step
        :key="step"
        v-if="step == 1"
        @next="handleStepVerify"
        :emailObj="obj"
      ></verify-step>

      <!-- password step -->
      <password-step
        :key="step"
        v-if="step == 2"
        :emailObj="obj"
      ></password-step>
    </transition>

  </div>
</template>

<script>
import { mapGetters } from "vuex";
import PasswordStep from "./steps/Password";
import VerifyStep from "./steps/Verify";
export default {
  components: {
    PasswordStep,
    VerifyStep,
  },
  data: () => ({
    step: 1,
    obj: {},
  }),
  computed: {
    ...mapGetters(["profile","resetCode"]),
  },
  methods: {
    handleStepVerify() {
      this.step = 2;
    },
  },
  created() {
    if(!this.resetCode){
      this.$router.replace('/dashboard/profile/info')
    }
    this.obj.email = this.profile.email;
  },
};
</script>

<style lang="scss">
.reset-password{
  max-width: 600px;
  margin: auto
}
</style>
